import type { NextPage } from 'next';
import Head from 'next/head';
import Page from '../src/containers/Login';
const Login: NextPage = () => {
	return (
		<>
			<Head>
				<title>Login Page</title>
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			</Head>
			<Page />
		</>
	);
};

export default Login;
