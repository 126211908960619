import React, { useState } from 'react';
import { Box, Button, Center, Container, FormControl, FormLabel, Grid, GridItem, Heading, Input, useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import useArkContext from '../provider/ArkProvider';

const LoginSchema = Yup.object().shape({
	email: Yup.string().email().required('Email is required'),
	password: Yup.string().required('Password is required'),
});

const Login = () => {
	const toast = useToast();
	const router = useRouter();
	const { login } = useArkContext();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleLogin = async (values: { email: string; password: string }) => {
		setIsSubmitting(true);
		try {
			if (!values.email || !values.password) {
				throw new Error('Invalid input');
			}
			await login(values.email, values.password);
			toast({
				title: 'Success!',
				description: 'Logged In Successfully, redirecting you to the dashboard in a few seconds.',
				status: 'success',
				duration: 2000,
				isClosable: true,
			});
			setTimeout(() => {
				router.push('/dashboard');
			}, 2000);
		} catch (e: unknown) {
			toast({
				title: 'Error!',
				description: "Looks like something wen't wrong",
				status: 'error',
				duration: 4000,
				isClosable: true,
			});
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Container maxW="container.lg" mt={10}>
				<Center>
					<Box rounded="md" width={{ md: 'container.sm' }}>
						<Formik
							enableReinitialize={true}
							initialValues={{
								email: '',
								password: '',
							}}
							validationSchema={LoginSchema}
							onSubmit={
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								(values: any, {}: any) => {
									handleLogin(values);
								}
							}
						>
							{({ values, handleChange, handleSubmit }) => (
								<Box className="itemBg" rounded="md" width={{ base: '100%' }} p="8">
									<Heading mb={10} fontSize="28">
										<Center>Login</Center>
									</Heading>
									<Form onSubmit={handleSubmit}>
										<Grid mt="6">
											<GridItem w="100%" mb="10">
												<FormControl isRequired>
													<FormLabel>Email</FormLabel>
													<Input
														onChange={handleChange}
														value={values.email}
														id="email"
														name="email"
														variant="form-input"
														type="text"
														size="md"
													/>
												</FormControl>
												<ErrorMessage name="email" render={(msg) => <Box color={'red.100'}>{msg}</Box>} />
											</GridItem>
											<GridItem w="100%">
												<FormControl>
													<FormLabel>Password</FormLabel>
													<Input
														onChange={handleChange}
														value={values.password}
														id="password"
														name="password"
														variant="form-input"
														type="password"
														size="md"
													/>
												</FormControl>
												<ErrorMessage name="password" render={(msg) => <Box color={'red.100'}>{msg}</Box>} />
											</GridItem>
										</Grid>

										<Grid>
											<GridItem w="100%">
												<Button
													isLoading={isSubmitting}
													type="submit"
													float="right"
													className="buttonGradient"
													mt={35}
													w={150}
													h={39}
												>
													Login
												</Button>
											</GridItem>
										</Grid>
									</Form>
								</Box>
							)}
						</Formik>
					</Box>
				</Center>
			</Container>
		</>
	);
};

export default Login;
